<template>
  <layout-horizontal>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-horizontal-layout-brand />
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template #breadcrumb>
      <breadcrumb />
    </template>

    <template #footer>
      <custom-footer v-show="showFooter" />
    </template>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import Navbar from './navbar/Navbar.vue'
import CustomFooter from '../@custom/layout/components/CsFooter.vue'
import Breadcrumb from '../@custom/layout/components/CsBreadcrumb.vue'

export default {
  components: {
    CustomFooter,
    LayoutHorizontal,
    AppCustomizer,
    Breadcrumb,
    Navbar,
    AppNavbarHorizontalLayoutBrand,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      showFooter: $themeConfig.layout.footer.visible,
    }
  },
}
</script>
